<template>
  <div class="p-5 flex flex-column">
    <ResearchTopCard v-if="getPath === '/research/library' || getPath === '/research/buylist'" />

    <spacer-h/>
    <div id="rd3-scrollto"></div>
    <div class="tab-container">

      <TabViewHeaders v-if="!mobile" :headers="headers" @change="onTabHeadersChange" :root-page="'/research'"/>
      <div v-if="mobile">
        <TabViewDropdown :headers="headers" @change="onTabHeadersChange" :root-page="'/research'"/>
        <spacer-h/>
      </div>


      <router-view></router-view>

      <HelpCard mode="research" class="pt-5" />

    </div>
  </div>
</template>
<script>
import {computed, onMounted, ref, watch} from "vue";
import SpacerH from '@/components/common/layout/SpacerH.vue'
import {useStore} from "vuex";
import TabViewDropdown from "@/components/nav/TabViewDropdown";
import TabViewHeaders from "@/components/nav/TabViewHeaders";
// import TabResearchLibrary from "@/components/tabbed_components/TabResearchLibrary";
import ResearchTopCard from "@/components/top_cards/ResearchTopCard";
import {useRoute} from "vue-router";
import HelpCard from "@/components/common/HelpCard";



export default {
  components: {
    ResearchTopCard,
    SpacerH,
    TabViewDropdown,
    TabViewHeaders,
    // TabResearchLibrary
    HelpCard
  },
  setup() {
    const mobile = ref(false)
    const route = useRoute()
    const store = useStore()
    const headers = computed(() => store.getters.getTabHeaders('research'))
    const screenSize = computed(() => store.getters.getTabView)

    const onTabHeadersChange = (payload) => {
      store.dispatch('setActiveHeader', {page: 'research', headers: payload})
    }

    const isRootPage = computed(() => {
      return route.path === '/reseaerch'
    })

    const getPath = computed(() => {
      return route.path
    })

    watch(screenSize, (val) => {
      mobile.value = val === 'mobile'
    })

    onMounted(() => {
      mobile.value = screenSize.value === 'mobile'
    })

    return {
      headers,
      mobile,
      onTabHeadersChange,
      isRootPage,
      getPath
    }
  },

}
</script>

<style scoped>
button {
  min-width: 220px;
  justify-content: center;
  margin: 5px;
  padding: 5px 45px;
}
</style>
