<template>
  <content-card-split :background="true" :collapsed="collapsed">
    <template #title>
      <card-title @toggleTopCard="toggleTopCard" :topcard="true">
        <template #title><h2>Research</h2></template>
      </card-title>
    </template>
    <template #content-left>
      <div class="content-container">
        <div class="content-title">
          <h3>FUND FOCUS</h3>
        </div>
        <div class="content">
          <div class="content-body">
            <h5 class="title">A quarterly update from our investment committee</h5>
            <div class="blurb">With market commentary covering the key factors affecting financial movements across the globe and a summary of selected funds from our buy list.</div>
          </div>
          <div class="content-actions-single">
            <a :href="clarityURL+'/newfundfocus'" target="_blank"><Button class="clarity-btn clarity-gradient-orange my-3">View Fund Focus</Button></a>
          </div>
        </div>
      </div>
    </template>
    <template #content-right>
      <div class="content-container">
        <div class="content-title">
          <h3>INVESTMENT BUY LIST</h3>
        </div>
        <div class="content">
          <div class="content-body">
            <h5 class="title">From our investment committee</h5>
            <div class="blurb">All funds on our buy list are selected and monitored by our in-house investment committee, using our rigorous research and screening methodology.</div>
          </div>
          <div class="content-actions">
            <Button class="clarity-btn clarity-gradient-orange my-3" @click="$router.push('/research/buylist')">View Buy List</Button>
            <a :href="clarityURL+'/toolsvideos'" target="_blank"><Button class="clarity-btn clarity-gradient-grey my-3">View Videos & Webinars</Button></a>
          </div>
        </div>
      </div>
    </template>
  </content-card-split>
</template>
<script>
import CardTitle from '@/components/common/CardTitle.vue'
import ContentCardSplit from '@/components/common/ContentCardSplit.vue'
import {inject, onBeforeUnmount, ref} from "vue";

export default {
  name: 'ResearchTopCard',
  components: {CardTitle, ContentCardSplit},
  setup() {
    const bus = inject('bus')
    const collapsed = ref(true)
    const clarityURL = process.env.VUE_APP_CLARITYURL;


    bus.on('small', () => {
      console.log('small detected')
    })
    bus.on('tablet', () => {
      console.log('tablet detected')
    })

    const toggleTopCard = () => {
      collapsed.value = !collapsed.value
    }

    onBeforeUnmount(() => {
        bus.off('small')
        bus.off('tablet')
    });

    return {
      collapsed,
      toggleTopCard,
      clarityURL
    }
  }
}
</script>
<style scoped lang="scss">
.content-container {
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include mob-sm {
    flex-direction: column;
  }
}

.content-body {
  padding-top: 20px;
  padding-bottom: 20px;

  .blurb {
    display: flex;
    @include mob {
      display: none;
    }
  }
}

.content-actions {
  display: flex;
  flex-direction: column;
  margin-left: 30px;

  button {
    min-width: 276px;
    padding-left: 36px;
    padding-right: 36px;
  }
}

.content-actions-single {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;

  button {
    min-width: 276px;
    padding-left: 36px;
    padding-right: 36px;
  }
}

button {
  min-width: 220px;
  justify-content: center;
  margin: 5px;
  padding: 5px 45px;
}
</style>
